import { getAuth } from 'firebase/auth';

export default class AuthService {
  static getCurrentUserId = (): string | null => {
    const currentUser = getAuth().currentUser;
    return currentUser ? currentUser.uid : null;
  };

  static logout = async (): Promise<void> => {
    return getAuth().signOut();
  };
}
