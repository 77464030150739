import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useTheme } from '@mui/material';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const theme = useTheme();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('Dashboard'),
        items: [
          {
            title: t('custom evaluators'),
            path: paths.dashboard.general.evaluators,
            icon: ICONS.user,
          },
          {
            title: t('pretrained evaluators'),
            path: paths.dashboard.user.cards,
            icon: ICONS.product,
          },
          {
            title: t('playground'),
            path: paths.dashboard.playground,
            icon: ICONS.chat,
            info: (
              <Label
                sx={{ backgroundColor: theme.palette.info.main, color: 'black' }}
                startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}
              >
                NEW
              </Label>
            ),
          },
          {
            title: t('model analytics'),
            path: paths.dashboard.general.analytics,
            icon: ICONS.analytics,
          },
          { title: t('sessions'), path: paths.dashboard.sessions, icon: ICONS.folder },
          { title: t('usage'), path: paths.dashboard.usage, icon: ICONS.file },
          {
            title: t('documentation'),
            path: 'https://gaveldocumentation.web.app/docs/intro',
            icon: ICONS.external,
          },

          // {
          //   title: t('banking'),
          //   path: paths.dashboard.general.banking,
          //   icon: ICONS.banking,
          // },
          // {
          //   title: t('app'),
          //   path: paths.dashboard.root,
          //   icon: ICONS.dashboard,
          // },
          // {
          //   title: t('ecommerce'),
          //   path: paths.dashboard.general.ecommerce,
          //   icon: ICONS.ecommerce,
          // },
          // {
          //   title: t('file'),
          //   path: paths.dashboard.general.file,
          //   icon: ICONS.file,
          // },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
    ],
    [t]
  );

  return data;
}
