import { initializeApp, getApp } from 'firebase/app';
import { getFirestore, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { FIREBASE_API } from 'src/config-global';

const initFirebaseApp = () => {
  let app;
  try {
    app = getApp();
  } catch (e) {
    // If getApp() throws an error, initialize the app
    app = initializeApp(FIREBASE_API);
  }
  return app;
};

const app = initFirebaseApp();
export default app;
