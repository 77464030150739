import _ from 'lodash';

export function camelToSnake(camelCaseData: { [key: string]: any }): { [key: string]: any } {
  return _.mapKeys(camelCaseData, (__, key) => _.snakeCase(key));
}

export function snakeToCamel(snakeCaseData: { [key: string]: any }): { [key: string]: any } {
  return _.mapKeys(snakeCaseData, (__, key) => {
    const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
    return camelCaseKey;
  });
}
