import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  DocumentSnapshot,
  collection,
  query,
  orderBy,
  where,
  getDocs,
} from 'firebase/firestore';
import app from './initFirebaseApp';

import type { Model } from './models';
import { snakeToCamel } from './utility';
import AuthService from '../AuthService';

const db = getFirestore(app);

export class ModelRepo {
  static collection = 'models';

  static async get(modelId: string): Promise<Model | null> {
    const docRef = doc(db, this.collection, modelId);
    const snapshot = await getDoc(docRef);

    return ModelRepo.fromSnapshot(snapshot);
  }

  static async getAll(): Promise<Model[]> {
    const uid = AuthService.getCurrentUserId();
    if (uid) {
      const modelsCollectionRef = collection(db, this.collection);
      const modelsQuery = query(
        modelsCollectionRef,
        where('uid', '==', uid), // Add this line for the where clause
        orderBy('created_at', 'desc')
      );
      const modelsSnapshot = await getDocs(modelsQuery);

      return modelsSnapshot.docs.map((doc) => ModelRepo.fromSnapshot(doc)) as Model[];
    }
    return [];
  }

  static async update(modelId: string, updatedModel: Partial<Model>): Promise<void> {
    const docRef = doc(db, this.collection, modelId);
    await updateDoc(docRef, updatedModel);
  }

  static async delete(modelId: string): Promise<void> {
    const docRef = doc(db, this.collection, modelId);
    await deleteDoc(docRef);
  }

  static fromSnapshot(snapshot: DocumentSnapshot): Model | null {
    if (snapshot.exists()) {
      const modelData = snakeToCamel(snapshot.data());

      return {
        id: snapshot.id,
        ...modelData,
        createdAt: modelData.createdAt.toDate(),
      } as unknown as Model;
    }
    return null;
  }
}

export default ModelRepo;
