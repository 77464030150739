import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  DocumentSnapshot,
  collection,
  query,
  orderBy,
  where,
  getDocs,
  limit,
  startAfter,
} from 'firebase/firestore';
import app from './initFirebaseApp';
import _ from 'lodash';

import type { Session } from './models';
import { snakeToCamel } from './utility';

const db = getFirestore(app);

export class SessionRepo {
  static collection = 'sessions';

  static async get(sessionId: string): Promise<Session | null> {
    const docRef = doc(db, this.collection, sessionId);
    const snapshot = await getDoc(docRef);

    return SessionRepo.fromSnapshot(snapshot);
  }

  static async getAll(uid: string, pageSize: number, lastDate?: Date): Promise<Session[]> {
    const sessionsCollectionRef = collection(db, this.collection);
    let sessionsQuery = query(
      sessionsCollectionRef,
      where('uid', '==', uid),
      orderBy('created_at', 'desc'),
      limit(pageSize)
    );

    // If a lastDoc is provided, paginate from that document
    if (lastDate) {
      sessionsQuery = query(sessionsQuery, orderBy('createdAt', 'desc'), startAfter(lastDate));
    }

    const sessionsSnapshot = await getDocs(sessionsQuery);

    const sessions = sessionsSnapshot.docs.map((doc) => SessionRepo.fromSnapshot(doc)) as Session[];

    return sessions;
  }

  static async update(sessionId: string, updatedSession: Partial<Session>): Promise<void> {
    const docRef = doc(db, this.collection, sessionId);
    await updateDoc(docRef, updatedSession);
  }

  static async delete(sessionId: string): Promise<void> {
    const docRef = doc(db, this.collection, sessionId);
    await deleteDoc(docRef);
  }

  static fromSnapshot(snapshot: DocumentSnapshot): Session | null {
    if (snapshot.exists()) {
      const sessionData = snakeToCamel(snapshot.data());

      return {
        id: snapshot.id,
        ...sessionData,
        createdAt: sessionData.createdAt.toDate(),
      } as Session;
    }
    return null;
  }
}

export default SessionRepo;
