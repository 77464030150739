import { v4 as uuidv4 } from 'uuid';

export interface Session {
  id: string;
  uid: string;
  interactionIds: string[];
  createdAt: Date;
}

export interface Evaluator {
  name: string;
  createdAt: Date; // Assuming this is a string representation of a datetime
  codename: string;
  description: string;
  scoreIds: string[];
  avatarUrl: string;
  coverUrl: string;
  role: string;
  price: number;
  id: string;
}

export interface User {
  modelIds: string[];
  evaluatorIds: string[];
  apiKey: string;
  totalInteractions: number;
  totalScored: number;
  createdAt: Date;
  email: string;
  displayName: string;
  scoreIds: string[];
  uid: string;
}

export const NEW_USER = (uid: string): User => ({
  modelIds: [],
  evaluatorIds: [],
  apiKey: uuidv4(),
  createdAt: new Date(),
  totalInteractions: 0,
  totalScored: 0,
  displayName: '',
  email: '',
  scoreIds: [],
  uid,
});

export interface Score {
  uid: string;
  name: string;
  color: string;
  count: number;
}

export interface ScoreField {
  val: number;
  author: string;
  name: string;
}

export interface Interaction {
  input: string;
  output: string;
  scores: ScoreField[];
  modelName: string;
  sessionId?: string | null;
  uid: string;
  createdAt: Date;
  id: string;
}

export interface ScoreStats {
  name: string;
  rangeCounts: number[];
  totalScore: number;
}

export interface Model {
  createdAt: Date;
  name: string;
  stats: ScoreStats[];
  uid: string;
  totalInteractions: number;
  totalScored: number;
}
