import { getAnalytics, logEvent } from 'firebase/analytics';
import app from './database/initFirebaseApp';
import AuthService from './AuthService';

const analytics = getAnalytics(app);

export class AnalyticsService {
  static log = (name: string, meta: any = {}) =>
    logEvent(analytics, name, { ...meta, uid: AuthService.getCurrentUserId() });
}
