import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  DocumentSnapshot,
  collection,
  getDocs,
  where,
  query,
} from 'firebase/firestore';
import app from './initFirebaseApp';
import { Interaction } from './models';
import { snakeToCamel } from './utility';
import AuthService from '../AuthService';

const db = getFirestore(app);

export class InteractionRepo {
  static collection = 'interactions';

  static async get(interactionId: string): Promise<Interaction | null> {
    const docRef = doc(db, this.collection, interactionId);
    const snapshot = await getDoc(docRef);

    return InteractionRepo.fromSnapshot(snapshot);
  }

  static async getBySessionId(sessionId: string): Promise<Interaction[]> {
    const interactionsCollectionRef = collection(db, this.collection);
    const interactionsQuery = query(
      interactionsCollectionRef,
      where('session_id', '==', sessionId),
      where('uid', '==', AuthService.getCurrentUserId())
    );
    const interactionsSnapshot = await getDocs(interactionsQuery);

    return interactionsSnapshot.docs.map((doc) =>
      InteractionRepo.fromSnapshot(doc)
    ) as Interaction[];
  }

  static async update(
    interactionId: string,
    updatedInteraction: Partial<Interaction>
  ): Promise<void> {
    const docRef = doc(db, this.collection, interactionId);
    await updateDoc(docRef, updatedInteraction);
  }

  static async delete(interactionId: string): Promise<void> {
    const docRef = doc(db, this.collection, interactionId);
    await deleteDoc(docRef);
  }

  static fromSnapshot(snapshot: DocumentSnapshot): Interaction | null {
    if (snapshot.exists()) {
      const interactionData = snakeToCamel(snapshot.data());
      return { id: snapshot.id, ...interactionData } as Interaction;
    }
    return null;
  }
}

export default InteractionRepo;
