import { ex } from '@fullcalendar/core/internal-common';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

const initSentry = () => {
  if (import.meta.env.MODE == 'production') {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [new Sentry.Replay()],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  } else {
    console.log('Sentry is disabled');
  }
};

export default initSentry;
