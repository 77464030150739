import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { UserRepo, User } from 'src/api/database';

const getCurrentUserId = (): string | null => {
  const currentUser = getAuth().currentUser;
  return currentUser ? currentUser.uid : null;
};

const useGetUser = (): User | null => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const currentUserId = getCurrentUserId(); // You need to implement this function.
    if (currentUserId) {
      UserRepo.get(currentUserId).then(setUser);
    } else {
      setUser(null);
    }
  }, []);

  return user;
};

export default useGetUser;
