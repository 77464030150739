import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  DocumentSnapshot,
  collection,
  addDoc,
  setDoc,
} from 'firebase/firestore';
import app from './initFirebaseApp';
import type { User } from './models';
import { camelToSnake, snakeToCamel } from './utility';
import AuthService from '../AuthService';

const db = getFirestore(app);

export class UserRepo {
  static collection = 'users';

  static async get(): Promise<User | null> {
    const uid = AuthService.getCurrentUserId();
    if (uid) {
      const docRef = doc(db, this.collection, uid);
      const snapshot = await getDoc(docRef);

      return UserRepo.fromSnapshot(snapshot);
    }
    return null;
  }

  static async update(userId: string, updatedUser: Partial<User>): Promise<void> {
    const docRef = doc(db, this.collection, userId);
    await updateDoc(docRef, camelToSnake(updatedUser));
  }

  static async delete(userId: string): Promise<void> {
    const docRef = doc(db, this.collection, userId);
    await deleteDoc(docRef);
  }

  static async set(uid: string, user: User): Promise<void> {
    const usersCollection = collection(db, this.collection);
    const userDocRef = doc(usersCollection, uid);
    return await setDoc(userDocRef, camelToSnake(user));
  }

  static fromSnapshot(snapshot: DocumentSnapshot): User | null {
    if (snapshot.exists()) {
      const userData = snakeToCamel(snapshot.data());
      return { ...userData } as User;
    }
    return null;
  }
}

export default UserRepo;
