import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  DocumentSnapshot,
  collection,
  where,
  getDocs,
  query,
} from 'firebase/firestore';
import app from './initFirebaseApp';
import type { Score } from './models';
import { snakeToCamel } from './utility';

const db = getFirestore(app);

class ScoreRepo {
  static collection = 'scores';

  static async get(scoreId: string): Promise<Score | null> {
    const docRef = doc(db, ScoreRepo.collection, scoreId);
    const snapshot = await getDoc(docRef);

    return ScoreRepo.fromSnapshot(snapshot);
  }

  static async update(scoreId: string, updatedScore: Partial<Score>): Promise<void> {
    const docRef = doc(db, this.collection, scoreId);
    await updateDoc(docRef, updatedScore);
  }

  static async delete(scoreId: string): Promise<void> {
    const docRef = doc(db, this.collection, scoreId);
    await deleteDoc(docRef);
  }

  static async getAllByIds(scoreIds: string[]): Promise<Score[]> {
    const scores = await Promise.all(scoreIds.map(ScoreRepo.get));
    return scores.filter((score) => score !== null) as Score[];
  }

  static async getAll(uid: string): Promise<Score[]> {
    const scoresCollection = collection(db, this.collection);

    // Create a query to get all scores with the specified uid
    const q = query(scoresCollection, where('uid', '==', uid));

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Map the query results to Score objects
    const scores = querySnapshot.docs.map((doc) => ScoreRepo.fromSnapshot(doc) as Score);
    return scores;
  }

  static fromSnapshot(snapshot: DocumentSnapshot): Score | null {
    if (snapshot.exists()) {
      const scoreData = snakeToCamel(snapshot.data());

      return { id: snapshot.id, ...scoreData } as unknown as Score;
    }
    return null;
  }
}

export default ScoreRepo;
